import clsx from 'clsx';
import Link from 'next/link';
import { HTMLAttributes } from 'react';

import { Container } from '@/components/container';
import { Image } from '@/components/image';

import { useMediaQuery } from '@/hooks/useMediaQuery';

import { ITiledBundle } from '@/types/new/tiled-bundles';

import styles from './SelectionsGrid.module.scss';

type Props = HTMLAttributes<HTMLDivElement> & {
  bundle: ITiledBundle;
};

export const SelectionsGrid = ({ bundle, className, ...rest }: Props) => {
  const bigItem = bundle.big_tile;
  const isMobile = useMediaQuery('768px');
  const smallItems = bundle.bundle_small_tiles.map((item) => item.small_tile);
  return (
    <Container className={styles.container}>
      <div className={clsx(styles.wrapper, className)} {...rest}>
        <div className={styles.grid}>
          <Link
            key={bigItem.name}
            href={bigItem.href}
            className={clsx(styles.element, styles.large)}
          >
            <span className={styles.title}>{bigItem.name}</span>
            <Image
              className={styles.image}
              fill
              priority
              src={isMobile ? bigItem.image.formats.small?.url ?? '' : bigItem.image.url}
              alt={bigItem.name}
            />
          </Link>
          {smallItems.map(({ name, media, href }) => (
            <Link key={name} href={href} className={clsx(styles.element, styles.default)}>
              <span className={styles.title}>{name}</span>
              <Image
                className={styles.image}
                fill
                loading="lazy"
                src={isMobile ? media.formats.small?.url ?? '' : media.url}
                alt={name}
              />
            </Link>
          ))}
        </div>
      </div>
    </Container>
  );
};
